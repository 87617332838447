/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Table as MaterialTable,
  TableBody,
  TableFooter,
  TablePagination,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import WarningModal from '@components/modals/warning-modal';
import { toast } from 'react-toastify';
import { formatDateUsToBr } from '@utils/format-date-us-to-br';
import api from '../../../services/api';
import { useStyles } from './styles';

const Header = () => {
  return (
    <>
      <TableCell>Convenio Grupo Nome</TableCell>
      <TableCell>Linhas</TableCell>
      <TableCell>Inicio vigência</TableCell>
      <TableCell>Fim vigência</TableCell>
      <TableCell>Editar</TableCell>
      <TableCell>Excluir</TableCell>
    </>
  );
};

const GridEmpresaCDC = () => {
  const [page, setPage] = useState(1);
  const [totalCdcTaxasEmp, setTotalCdcTaxasEmp] = useState(0);
  const [renderedCdcTaxasEmp, setRenderedCdcTaxasEmp] = useState([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<number>();
  const [isDeleted, setIsDeleted] = useState<number>(0);
  const history = useHistory();
  const classes = useStyles();

  const confirmModalDelete = (id: number) => {
    setIsOpen(true);
    setIdToDelete(id);
  };

  useEffect(() => {
    const getCdcTaxasEmp = async () => {
      await api.get(`/cdc-taxas-emp/load?page=${page}`).then((response) => {
        const cdcTaxasEmpData = response.data.cdc_taxas_emp.map((taxa: any) => {
          const taxaToEdit = taxa.id;

          const linhas = taxa.cdc_taxas_emp_linhas.map(
            (linha) => linha.linhas.linha,
          );

          return (
            <TableRow className={classes.header}>
              <TableCell>
                {taxa.convenio_grupos?.convenio_grupo_nome?.nome ||
                  'SEM GRUPO INFORMADO'}
              </TableCell>
              <TableCell>{linhas.join(', ')}</TableCell>
              <TableCell>{formatDateUsToBr(taxa.inicio_vigencia)}</TableCell>
              <TableCell>{formatDateUsToBr(taxa.fim_vigencia)}</TableCell>
              <TableCell>
                <MdEdit size={18} onClick={() => handleEditTaxa(taxaToEdit)} />
              </TableCell>
              <TableCell>
                <MdDelete
                  size={18}
                  onClick={() => confirmModalDelete(taxa.id)}
                  color="red"
                />
              </TableCell>
            </TableRow>
          );
        });

        setPage(response.data.page);
        setTotalCdcTaxasEmp(response.data.total);
        setRenderedCdcTaxasEmp(cdcTaxasEmpData);
      });
    };
    getCdcTaxasEmp();
  }, [page, isDeleted]);

  const handleEditTaxa = (id: string) => {
    history.push(`/taxas/cadastro-remuneracao/empresa-cdc?id=${id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDelete = async () => {
    try {
      await api.post('/cdc-taxas-emp/delete', { id: idToDelete });
      toast.success(`Taxa deletada com sucesso`);
      setIsDeleted(isDeleted + 1);
    } catch (e) {
      const error = e as any;
      toast.error(`${error.response.data.message}`);
    } finally {
      setIsOpen(false);
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <MaterialTable className={classes.root} title="Taxas" color="primary">
          <TableHead>
            <TableRow className={classes.header}>
              <Header />
            </TableRow>
          </TableHead>
          <TableBody className={classes.body}>{renderedCdcTaxasEmp}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                rowsPerPageOptions={[10]}
                count={totalCdcTaxasEmp}
                rowsPerPage={10}
                page={page - 1}
                onChangePage={handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </MaterialTable>
      </TableContainer>
      <WarningModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleDelete}
        message="Deseja remover a taxa?"
      />
    </>
  );
};

export default GridEmpresaCDC;
